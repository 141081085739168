import React, { useState } from 'react'

import { Box, Button, Input, Label } from 'theme-ui'
import PhoneInput from '../shared/PhoneInput'
import { gql, useMutation } from '@apollo/client'

// Mutation
const REQUEST_EDIT_LINK = gql`
  mutation RequestAlertsEditLink($phone: String!) {
    requestAlertsEditLink(phone: $phone) {
      success
      data
    }
  }
`

export const RequestAlertsEditModalContent = () => {
  const [phone, setPhone] = useState(null)
  const [email, setEmail] = useState(null)

  const [requestAlertsEditLink, { data, loading, error }] =
    useMutation(REQUEST_EDIT_LINK)

  return (
    <>
      {data?.requestAlertsEditLink?.success ? (
        <h6>A message has been sent! Please check your phone or email.</h6>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault()
            requestAlertsEditLink({
              variables: { email, phone },
            })
          }}
        >
          {error?.message?.includes('not found') ? (
            email ? (
              <>Sorry we could not find your account</>
            ) : (
              <>
                <Label sx={{ my: '0px', mb: '30px' }}>
                  Not found... try searching by email?
                </Label>
                <Input
                  type="email"
                  email
                  placeholder="email@email.com"
                  value={email}
                  setValue={(val) => {
                    setEmail(val)
                  }}
                  required
                />
              </>
            )
          ) : (
            <>
              <h6 sx={{ my: '0px', mb: '30px' }}>
                What phone number would you like to edit alerts for?
              </h6>
              <PhoneInput
                value={phone}
                setValue={(val) => {
                  setPhone(val)
                }}
                required
              />
            </>
          )}
          {error && (
            <Box sx={{ color: 'red', textAlign: 'left' }}>{error.message}</Box>
          )}

          <Button
            sx={{ mt: 20, width: '100%' }}
            disabled={!phone || loading}
            type="submit"
          >
            {loading ? 'Sending...' : 'Send'}
          </Button>
        </form>
      )}
    </>
  )
}
