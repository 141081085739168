/** @jsx jsx */
import React from 'react'
import { graphql } from 'gatsby'
import { jsx, Flex } from 'theme-ui'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Container from '../components/Container'
import Header from '../components/Header'
import { sortBy } from 'lodash'
import { useSearchParams } from '../hooks/useSearchParams'
import { useLocalStorage } from 'react-use'
import { RequestAlertsEditLinkButton } from '../components/alerts/RequestAlertsEditLinkButton'
import { MyAlerts } from '../components/alerts/MyAlerts'

const Alerts = ({ data }) => {
  const [item] = useLocalStorage('subscribed')
  const { user } = useSearchParams()
  const states = data.allContentfulState.nodes
  const sortedStates = sortBy(states, 'name')

  return (
    <Layout>
      <SEO
        title={'Alerts | Safe Beach Day'}
        description="Manage your alerts for your favorite beaches."
        // image={data.contentfulAsset && data.contentfulAsset.file.url}
      />
      <Container>
        <Flex
          sx={{
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Header
            hideSearch
            height={'30vh'}
            headerText={'Beach Alert Manager'}
          />
        </Flex>
        <Flex
          sx={{
            p: [15, 40],
            maxWidth: '1200px',
            margin: 'auto',
            gap: 10,
            flexDirection: 'column',
          }}
        >
          {!user && !item?.id ? (
            <Flex
              sx={{
                flexDirection: 'column',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
              }}
            >
              <h3>Looking to edit your alerts?</h3>
              <RequestAlertsEditLinkButton />
            </Flex>
          ) : (
            <MyAlerts
              userId={user || item?.id}
              subscribedTo={item?.lists}
              states={sortedStates}
            />
          )}
        </Flex>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query Alerts {
    allContentfulState {
      nodes {
        name # State name
        counties {
          name # County name
          regions {
            beaches {
              name
              slug
              id
              agencyName
              hideSubscribeButton
              watchtowerAgencyId
              agencyLogo {
                gatsbyImageData(width: 155)
              }
            }
          }
        }
      }
    }
  }
`

export default Alerts
