import React, { Fragment, useEffect, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Button, Card, Flex } from 'theme-ui'

export const SubscribeToAgencyAlertsCard = ({
  agencyId,
  agencyName,
  agencyLogo,
  beachArray,
  userId,
  isSubbed,
  handleSubscribe,
  handleUnsubscribe,
  error,
  isLoading,
}) => {
  const [subbed, setSubbed] = useState(isSubbed)

  useEffect(() => {
    setSubbed(isSubbed)
  }, [isSubbed])

  const canSub = beachArray?.filter(
    (beach) => !beach.hideSubscribeButton && beach.watchtowerAgencyId
  )

  return (
    <Flex
      sx={{
        borderRadius: '13px',
        padding: ['13px', '27px'],
        marginBottom: 4,
        position: 'relative',
        backgroundColor: '#fff',
        boxShadow: '0px 2px 10px rgba(194, 210, 252, 0.8)',
        flexDirection: ['column', null, 'row'],
        alignItems: 'center',
      }}
    >
      <Flex
        sx={{
          flex: '1 1 20%',
          overflow: 'visible',
          backgroundColor: 'transparent',
          maxWidth: ['100px', '125px', '155px'],
          marginRight: ['0px', null, 3],
          marginBottom: ['32px', null, '0px'],
        }}
      >
        {agencyLogo && (
          <GatsbyImage
            image={agencyLogo.gatsbyImageData}
            layout={'fullWidth'}
            objectFit={'contain'}
            alt={`${agencyName} Lifeguarding Agency Logo`}
          />
        )}
      </Flex>
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '5px',
          width: '100%',
        }}
      >
        <h4 sx={{ my: '0px', fontSize: 4 }}>{agencyName}</h4>
        <p sx={{ margin: '0px' }}>
          The following beaches are administered by {agencyName}:
        </p>
        <ul sx={{ margin: '0px', px: '14px' }}>
          {canSub?.map((beach) => {
            return <li key={beach.name}>{beach.name}</li>
          })}
        </ul>
        <Flex
          sx={{
            justifyContent: 'center',
            marginTop: ['32px', null, '0px'],
            top: '27px',
            right: '27px',
            position: ['static', null, 'absolute'],
            gap: 2,
          }}
        >
          {/* <ModifyAlertsCategoriesModal
            agencyName={agencyName}
            agencyId={agencyId}
            userId={userId}
            subscribedStatus={subbed}
          /> */}
          {canSub?.length > 0 && (
            <Button
              sx={{
                backgroundColor: subbed ? 'gray600' : 'redOrange',
                alignSelf: 'center',
                minWidth: '131px',
              }}
              onClick={async () => {
                const res = subbed
                  ? await handleUnsubscribe({
                      variables: {
                        agencyId: agencyId,
                        subscriberId: userId,
                        agencyName: agencyName,
                      },
                    })
                  : await handleSubscribe({
                      variables: {
                        agencyIds: [agencyId],
                        subscriber: {
                          subscriberId: userId,
                        },
                        agencyName: agencyName,
                      },
                    })
                if (!res.errors) {
                  setSubbed(!subbed)
                }
              }}
              disabled={isLoading}
            >
              {isLoading ? '...' : subbed ? 'Unsubscribe' : 'Subscribe'}
            </Button>
          )}
        </Flex>
        {error && (
          <span sx={{ color: 'red', py: '4px' }}>
            Error subscribing. Please contact support.
          </span>
        )}
      </Flex>
    </Flex>
  )
}
