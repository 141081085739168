import { gql } from '@apollo/client'

export const SUB_TO_BEACHES = gql`
  mutation SubscribeToAgencies(
    $agencyIds: [ID]!
    $subscriber: SubscriberInput!
  ) {
    subscribeToAgencies(agencyIds: $agencyIds, subscriber: $subscriber) {
      success
      data
    }
  }
`

export const UNSUB_TO_BEACH = gql`
  mutation unsubscribeFromAgency(
    $agencyId: ID
    $subscriberId: ID!
    $all: Boolean
  ) {
    unsubscribeFromAgency(
      agencyId: $agencyId
      subscriberId: $subscriberId
      all: $all
    ) {
      success
      data
    }
  }
`

export const UPDATE_AGENCY_ALERT_CATEGORIES = gql`
  mutation updateAgencyAlertCategories(
    $categoryNames: [AgencyAlertCategoryEnum!]!
    $agencyId: ID!
    $subscriberId: ID!
  ) {
    updateAgencyAlertCategories(
      categoryNames: $categoryNames
      agencyId: $agencyId
      subscriberId: $subscriberId
    ) {
      success
      data
    }
  }
`
