import React from 'react'
import { Button, Flex, Spinner } from 'theme-ui'

import { useQuery, useMutation } from '@apollo/client'
import { UNSUB_TO_BEACH } from '../../operations/mutations'
import { GET_ALERTS } from '../../operations/queries'
import { AgencyAlertsSubscriptionsList } from './AgencyAlertsSubscriptionsList'

export const MyAlerts = ({ userId, states, subscribedTo }) => {
  const {
    data: queryData,
    error,
    refetch,
  } = useQuery(GET_ALERTS, {
    skip: !userId,
    variables: {
      subscriberId: userId,
    },
  })

  const [unsubscribeFromBeach, { loading: unsubLoading }] = useMutation(
    UNSUB_TO_BEACH,
    {
      variables: {
        all: true,
        subscriberId: userId,
      },
      onCompleted: () => {
        refetch()
      },
    }
  )

  const subscribedLists =
    queryData?.listsBySubscriber?.data.map((d) => d.id).length > 0
      ? queryData?.listsBySubscriber?.data.map((d) => d.id)
      : subscribedTo

  if (error) return 'Error loading alerts, come back later...'

  return (
    <>
      {!queryData ? (
        <Flex
          sx={{
            width: '100%',
            height: '400px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner width={20} />
        </Flex>
      ) : (
        <>
          <Flex sx={{ width: '100%', justifyContent: 'flex-end' }}>
            <Button
              onClick={unsubscribeFromBeach}
              sx={{ backgroundColor: 'highRisk' }}
            >
              {unsubLoading ? 'Unsubscribing...' : 'Unsubscribe From All'}
            </Button>
          </Flex>

          <AgencyAlertsSubscriptionsList
            states={states}
            userId={userId}
            subscribedLists={subscribedLists}
            onFinish={refetch}
          />
        </>
      )}
    </>
  )
}
