import React from 'react'
import { ModalButton } from '../shared/ModalButton'
import { RequestAlertsEditModalContent } from './RequestAlertsEditModalContent'

export const RequestAlertsEditLinkButton = () => {
  return (
    <ModalButton
      modalStyles={{ maxWidth: '600px' }}
      modalHeight={'auto'}
      buttonText={'Edit My Alerts'}
      modalHeaderStyles={{
        padding: '20px',
      }}
      renderModalContent={() => <RequestAlertsEditModalContent />}
    />
  )
}
