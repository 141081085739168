import React, { Fragment } from 'react'
import { flatMap } from 'lodash'
import { Box } from 'theme-ui'

import { SUB_TO_BEACHES, UNSUB_TO_BEACH } from '../../operations/mutations'
import { SubscribeToAgencyAlertsCard } from './SubscribeToAgencyAlertsCard'
import { useMutation } from '@apollo/client'

export const AgencyAlertsSubscriptionsList = ({
  states = [],
  userId,
  subscribedLists,
  onFinish,
}) => {
  const [subscribeToBeaches, { loading: subLoading, error: subError }] =
    useMutation(SUB_TO_BEACHES, { onCompleted: () => onFinish() })

  const [unsubscribeFromBeach, { loading: unsubLoading, error: unsubError }] =
    useMutation(UNSUB_TO_BEACH, { onCompleted: () => onFinish() })

  const error = subError || unsubError
  const isLoading = subLoading || unsubLoading
  const currentOperation =
    subLoading.currentOperation || unsubLoading.currentOperation

  const groupBy = (key) => (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key]
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
      return objectsByKeyValue
    }, {})

  const groupByAgencyId = groupBy('watchtowerAgencyId')

  return (
    <>
      {process.env.GATSBY_STAGE !== 'production' && (
        <SubscribeToAgencyAlertsCard
          agencyId={'ck23sftbg0000f79ke15rhy4p'}
          name={'DEMO BEACH'}
          userId={userId}
          isLoading={isLoading}
          handleSubscribe={subscribeToBeaches}
          handleUnsubscribe={unsubscribeFromBeach}
          currentOperation={currentOperation}
          error={error}
          isSubbed={subscribedLists?.some((sl) =>
            sl.includes('ck23sftbg0000f79ke15rhy4p')
          )}
        />
      )}
      {states.map((state) => {
        return (
          <Box
            key={state.name}
            sx={{ my: 4 }}
            id={state.name.replace(/\s+/g, '-').toLowerCase()}
          >
            <h2
              sx={{
                fontSize: '32px',
                display: 'flex',
                justifyContent: ['center', 'flex-start'],
                backgroundColor: 'gray300',
                py: 4,
                paddingLeft: [0, 2],
                borderRadius: '0.2rem',
              }}
            >
              {state.name}
            </h2>
            {state.counties
              ?.filter((county) => {
                return county.regions?.some((r) =>
                  r.beaches?.some(
                    (b) => b.watchtowerAgencyId && !b.hideSubscribeButton
                  )
                )
              })
              ?.map((county) => {
                return (
                  <Box key={county.name}>
                    <h3 sx={{ fontSize: '24px' }}>
                      {county.name} Beach Safety Agencies:
                    </h3>
                    {flatMap(
                      county.regions
                        .filter((region) => region.beaches)
                        .map((region) => {
                          const beachesByAgency = groupByAgencyId(
                            region.beaches
                          )
                          return Object.entries(beachesByAgency)
                        })
                        .map((agencies) => {
                          return agencies
                            .filter((a) => a[1].length > 0)
                            .map((agency) => {
                              const agencyId = agency[0]
                              const agencyBeaches = agency[1]
                              const agencyLogo = agencyBeaches[0].agencyLogo
                              const agencyName = agencyBeaches[0].agencyName

                              return (
                                <SubscribeToAgencyAlertsCard
                                  key={agencyId}
                                  agencyId={agencyId}
                                  userId={userId}
                                  agencyName={agencyName}
                                  agencyLogo={agencyLogo}
                                  beachArray={agencyBeaches}
                                  handleSubscribe={subscribeToBeaches}
                                  handleUnsubscribe={unsubscribeFromBeach}
                                  isLoading={isLoading}
                                  currentOperation={currentOperation}
                                  error={error}
                                  isSubbed={subscribedLists?.some((sl) =>
                                    sl.includes(agencyId)
                                  )}
                                />
                              )
                            })
                        })
                    )}
                  </Box>
                )
              })}
          </Box>
        )
      })}
    </>
  )
}
